import React from "react"
import styled from "styled-components"
import { navigate } from "gatsby"
import { useLocation } from "@reach/router"
import "../components/ErrorSection/ErrorStyle.css"
import ImgError from "../images/404-transfez.png"
import orbsRight from "../images/404-orbs-right.png"
import orbsLeft from "../images/404-orbs-left.png"
import orbsCenter from "../images/404-orbs-center.png"
import Seo from "../components/SEOComponents/Seo"
import { CustomButton } from "../components/Buttons/parent"
// import Layout from "../components/layout"
// import SEO from "../components/seo"

function NotFoundPage() {
  const location = useLocation()
  const isEnglish = location.pathname.includes("/en")
  const Title1 = isEnglish ? "Page Not Found" : "Halaman Tidak Ditemukan"
  const Title2 = isEnglish
    ? "Keep Calm, There is an error occured."
    : "Tetap Tenang, ada sedikit kesalahan."
  const Title3 = isEnglish
    ? "The page you are looking for have been removed, erased or unavailable."
    : "Halaman yang Anda cari sepertinya telah dipindahkan, dihapus, atau tidak tersedia."
  const Button1 = isEnglish ? "Back" : "Kembali"
  const onClick = e => {
    e.preventDefault()
    navigate(-1)
  }
  return (
    <>
      <Seo title="error" />
      <MainDiv>
        <ImgOrbs src={orbsRight} alt="orbs" top="-100px" right="-120px" />
        <ImgOrbs src={orbsLeft} alt="orbs" bottom="-50px" left="-80px" />
        <ContentDiv>
          <TextDiv>
            <ImgCenter src={orbsCenter} alt="404 page" />
            <MainText>{Title1}</MainText>
            <SecondText>
              {Title2} <br />
              {Title3}
            </SecondText>
            <CustomButton
              onClick={onClick}
              bcolor="white"
              color="#db64a0"
              hoverBcolor="#ebebeb"
              padding="10px 25px"
            >
              {Button1}
            </CustomButton>
          </TextDiv>
        </ContentDiv>
      </MainDiv>
    </>
  )
}

export default NotFoundPage

const MainDiv = styled.div`
  background-color: #db64a0;
  position: relative;
  height: 100vh;
  overflow: hidden;
`

const ContentDiv = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`

const TextDiv = styled.div`
  margin-top: -100px;
  text-align: center;
  position: relative;
  z-index: 2;
`

const MainText = styled.h2`
  font-size: 3.5rem;
  font-family: "Typefez-black";
  color: #102c57;
`

const SecondText = styled.p`
  margin-bottom: 25px;
  color: ${p => p.color || "white"};
  font-family: ${p => p.ffamily || "Typefez-med"};
  font-size: 20px;
`

// const CustomButton = styled.button`
//   color: #ffd27c;
//   border: 1px solid #196e97;
//   background-color: #196e97;
//   text-transform: uppercase;
//   font-size: 0.875rem;
//   line-height: 1.5;
//   padding: 0.25rem 0.5rem;
//   font-family: "GothamLight";
//   font-weight: 600;
//   cursor: pointer;
//   border-radius: 0.2rem;
//   box-shadow: none;
// `

const ImgOrbs = styled.img`
  position: absolute;
  z-index: 0;
  width: ${p => p.width || "450px"};
  top: ${p => p.top};
  bottom: ${p => p.bottom};
  right: ${p => p.right};
  left: ${p => p.left};
  @media (max-width: 768px) {
    width: 250px;
  }
`
const ImgCenter = styled.img`
  width: 500px;
`
