import React from "react"
import styled from "styled-components"

export const CustomButton = ({ children, onClick, isDisabled, ...props }) => {
  return (
    <CustomButtonStyled
      onClick={onClick}
      disabled={isDisabled}
      cursor={isDisabled && "no-drop !important"}
      bcolor={isDisabled && "#d3d3d3"}
      hoverBcolor={isDisabled && "#d3d3d3"}
      {...props}
    >
      {children}
    </CustomButtonStyled>
  )
}

export const OutlineButton = ({ children, onClick, ...props }) => {
  return (
    <OutlineButtonStyled onClick={onClick} {...props}>
      {children}
    </OutlineButtonStyled>
  )
}

const CustomButtonStyled = styled.button`
  color: ${p => p.color || "white"};
  font-family: "Typefez-semibold";
  background-color: ${p => p.bcolor || "#db64a0"};
  font-size: 16px;
  padding: ${p => p.padding || "4px 16px"};
  margin-top: ${p => p.marginTop};
  margin-bottom: ${p => p.marginBottom};
  border-radius: 8px;
  border: none;
  width: ${p => p.width};
  cursor: ${p => p.cursor};
  &:hover {
    background-color: ${p => p.hoverBcolor || "#b65384"};
  }
`
const OutlineButtonStyled = styled.button`
  color: #db64a0;
  font-family: "Typefez-semibold";
  background-color: ${p => p.backgroundColor || "#fff"};
  font-size: 16px;
  margin-top: ${p => p.marginTop};
  margin-bottom: ${p => p.marginBottom};
  padding: ${p => p.padding || "4px 16px"};
  border-radius: 8px;
  border: 1px solid #db64a0;
  width: ${p => p.width};
`
